<template lang="pug">
    v-app(class="main-app overflow-hidden")
        
        v-navigation-drawer(
            app
            color="indigo darken-2"
            )
            v-row
                v-col
                    v-list(rounded)
                        v-subheader( class="white--text text-lg-h5 font-extrabold ") Reports
                        v-list-item-group(  v-modle="item" color="white" )
                            v-list-item(
                                v-for="(item, i) in items"
                                :key="i"
                                :to="item.to"
                                exact-path 
                                dark
                            )
                                v-list-item-content
                                    v-list-item-title( v-text="item.name" )
                                
                            
                    

        v-main(class="overflow-hidden")
            router-view
            
</template>

<script>
export default {
    data() {
        return {
            items:[
                {
                    name:'Last call 12+ months',
                    to: {
                        name: 'lastCall'
                    }
                },
                {
                    name:'Without planned calls',
                    to: {
                        name: 'noPlannedCall'
                    }
                }
            ]
        }
    },
}
</script>

<style scoped>
.main-app{
    font-weight: normal !important;
}
.v-main {
    padding: 0px !important;
}
</style>